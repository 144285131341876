import logo from "@assets/images/tn_logo.png";
import { navigate } from "@helpers/navigator";
import { removeItem } from "@helpers/storage";
import { Button } from "@mui/material";
import { Divider, Modal } from "antd";
import React, { useEffect, useState } from "react";
const Header = ({ hide }) => {
  const [open_logout, set_open_logout] = useState(false);
  const open_model = () => {
    set_open_logout(true);
  };
  const close_model = () => {
    set_open_logout(false);
  };
  const logout = () => {
    removeItem("session_id");
    removeItem("foreign");

    navigate("/");
  };
  return (
    <div className="custom-row header-style">
      <div className="col-8 align-center text-white">
        <img src={logo} alt="logo" height="50" className="px-2" />
        <span style={{ fontSize: "medium" }}>Tamil Nadu ePass</span>
      </div>
      <div className="col-3 align-center text-white-smaller">
        {hide ? (
          ""
        ) : (
          <Button
            style={{ backgroundColor: "#4d2d18" }}
            variant="contained"
            onClick={open_model}
          >
            Logout
          </Button>
        )}
      </div>
      <Modal
        title={"Logout"}
        open={open_logout}
        // onOk={handle_ok}
        onCancel={close_model}
        footer={null}
      >
        <Divider />
        <div className="text-start">
          <h3>Are you sure you want to logout?</h3>
        </div>
        <Divider />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            style={{ marginRight: "10px" }}
            onClick={close_model}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={logout}>
            Logout
          </Button>
        </div>
      </Modal>
    </div>
  );
};
export default Header;
