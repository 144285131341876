export const ProjectName = "TN ePass";
export const defaultPageLimit = 999999999999;
export const defaultPageNumber = 1;
export const page_count = 12;
export const operator_matches_page_count = 1;
export const DEFAULT_COUNTRY_CODE = "in";
export const DEFAULT_STATE_CODE = "tn";

export const GOOGLE_API_KEY = "AIzaSyCVY8hRJ-X4VVYVuDECrRQynxwvuk6Kgow";
export const LOCATION_PROVIDER = "google";

export const brand = {
  name: "TN ePass",
  desc: "TN ePass",
  prefix: "TN ePass",
  footerText: "TN ePass",
  logoText: "TN ePass",
};

export const titleException = ["/"];

export const MenuContent = [
  // {
  //   key: "E-Pass",
  //   name: "E-Pass",
  //   icon: "ion-ios-document",
  //   child: [
  //     {
  //       key: "reimbursement",
  //       name: "Reimbursement",
  //       link: "/reimbursement",
  //       icon: "ion-ios-document",
  //     },
  //   ],
  // },
  {
    key: "e-pass-download",
    name: "Apply E-Pass",
    link: "/apply-epass",
    icon: "ion-ios-document",
  },
];

export const StatusCodes = {
  ["500"]: "Something went wrong",
  ["422"]: "Invalid Data",
  ["404"]: "Not Found",
  ["409"]: "Duplicate",
  ["412"]: "Not Found",
};

// Global UI Action
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const OPEN_MENU = "OPEN_MENU";
export const CLOSE_MENU = "CLOSE_MENU";
export const OPEN_SUBMENU = "OPEN_SUBMENU";
export const CHANGE_THEME = "CHANGE_THEME";
export const CHANGE_RANDOM_THEME = "CHANGE_RANDOM_THEME";
export const CHANGE_MODE = "CHANGE_MODE";
export const CHANGE_GRADIENT = "CHANGE_GRADIENT";
export const CHANGE_DECO = "CHANGE_DECO";
export const CHANGE_BG_POSITION = "CHANGE_BG_POSITION";
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const CHANGE_DIRECTION = "CHANGE_DIRECTION";
export const LOAD_PAGE = "LOAD_PAGE";

export const destination = {
  nilgiris: "84be973a-5e86-4556-b310-41f4c9f82b30",
  kodaikanal: "8c9858e7-304a-451f-8be5-574b76332c44",
};

export const destination_id = {
  "84be973a-5e86-4556-b310-41f4c9f82b30": "Nilgiris",
  "8c9858e7-304a-451f-8be5-574b76332c44": "Kodaikanal",
};
